<template>
  <context-menu
    ref="contextMenu"
    class="trip-context-menu">
    <li
      v-for="option in contextMenuOptions"
      :key="`trip_context_menu_${option.type}`"
      @click="onSelect(option)">
      {{ option.label }}
    </li>
  </context-menu>
</template>

<script>
import tripContextMenuMixin from '@/mixins/trip-context-menu-mixin'

const ContextMenu = () => import('vue-context-menu')

export default {
  name: 'trip-context-menu',
  components: {
    ContextMenu
  },
  mixins: [tripContextMenuMixin],
  data() {
    return {
      selectedTrip: null
    }
  },
  methods: {
    open(trip) {
      this.selectedTrip = trip
      this.$refs.contextMenu.open()
    },
    onSelect(value) {
      this.$emit('select', this.selectedTrip, value)
    }
  }
}
</script>

<style lang="scss" scoped>
.trip-context-menu {
  li {
    width: 192px;
    @extend %small;
  }
}
</style>
